import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';; 
import { tap, map, catchError } from 'rxjs/operators';
import { Usuario } from '../modelos/usuario.model';
import { configuracion } from '../../config';
import { WebsocketsService } from './websockets.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public usuario: Usuario;

  constructor(
    private http: HttpClient, 
    private wsService: WebsocketsService
  ) { } 

  get token(): string {
    return localStorage.getItem('token') || '';
  }
  get pregunta(): string {
      return localStorage.getItem('pregunta') || '';
  }
  guardarToken( token: string) { 
    localStorage.setItem('token', token );  
  }
  guardarPregunta( pregunta: string) { 
    localStorage.setItem('pregunta', pregunta );  
  }
  borrarToken() { 
    localStorage.removeItem('token' );  
  }
 
  registro(formulario: any ){ 
    return this.http.post(`${ configuracion.base_url }/registro`, formulario )
                .pipe(
                  tap( (resp: any) => {
                    console.log("Respuesta: ", resp);
                    this.guardarToken( resp.token );
                    this.wsService.idEncuesta = resp.idEncuesta;
                    return resp.token;
                })
              );
  }
  validarToken(token: string ){ 
    return this.http.post(`${ configuracion.base_url }/api/validarTokenAlumno`, token )
                .pipe(
                  tap( (resp: any) => {
                    console.log("Respuesta: ", resp);
                    return resp;
                    //this.guardarToken( resp.token );
                  })
                );
  }
  getEstatusActual(){ 
    return this.http.get(`${ configuracion.base_url }/api/estatus-actual`, { } )
                .pipe(
                  tap( (resp: any) => {
                    console.log("Respuesta: ", resp);
                    return resp;
                    //this.guardarToken( resp.token );
                  })
                );
  }

}
