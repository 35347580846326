import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketsService } from './servicios/websockets.service';
import { UsuarioService } from './servicios/usuario.service';
import { Observable } from 'rxjs';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public wsService: WebsocketsService,
    public usuarioService: UsuarioService
  ){
    this.wsService.getPregunta().subscribe( (msg: any) => {
        console.log("Activar pregunta ");
        this.wsService.idPregunta = msg.idPregunta;
        if(this.wsService.idEncuesta != msg.idEncuesta){
          this.router.navigate(['/registro']); 
        }else{

          this.wsService.respuestas = Number( msg.respuestas.length );
          this.wsService.respuestaActual = msg.respuesta;

          this.router.navigate(['/pregunta']); 
        } 
        
    }, err => {

    })
    this.wsService.getApagarPregunta().subscribe( msg => {
        console.log("Desactivar pregunta " );
        
        if(this.wsService.idPregunta != ''){
          this.wsService.sinContestar++;
          this.wsService.idPregunta = '';
        }
        this.router.navigate( ['/espera'] ); 
    }, err => {

    });
    this.wsService.cerrarEncuesta().subscribe( msg => {
        console.log("Cerrar encuesta"); 
        this.usuarioService.borrarToken();
        this.router.navigate( ['/agradecimiento'] ); 
    }, err => {

    }); 
    this.usuarioService.getEstatusActual().subscribe(
      msg => {
        this.wsService.idPregunta = msg.idPregunta;
        if(this.wsService.idEncuesta != msg.idEncuesta){
          this.usuarioService.borrarToken();
        } 
        console.log("Cargando el estatus actual ");
      }, err => {
        console.log("Error al cargar estatus actual.");
      }
    );
  }
  ngOnDestroy(): void {
     
  }

  ngOnInit(){
    console.log("Estatus del socket", this.wsService.socketStatus);
  } 
}
