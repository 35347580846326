import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../servicios/usuario.service'; 

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad { 
  
  constructor( 
    private usuarioService: UsuarioService,
    private router: Router, 
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    console.log("Se autentica 2");
    if( this.usuarioService.token != '' ){
      return true;  
    }else{
      return false;
    } 
  }

  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      console.log("Se autentica");
      if( this.usuarioService.token != '' ){
        return true;  
      }else{
        return false;
      }
        
      // return (this.usuarioService.role === 'ADMIN_ROLE') ? true : false;

  }
}
