import { Component, Input } from '@angular/core';
import { MultiDataSet, Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-dona',
  templateUrl: './dona.component.html',
  styles: [
  ]
})
export class DonaComponent{
  
  @Input() title: string = 'Resultado';

  @Input('labels') doughnutChartLabels: Label[] = ['Correctas', 'Incorrectas'];
  @Input('data') doughnutChartData: MultiDataSet = [
    [0, 0],
  ];  
  public pieChartPlugins = [
    /*{
    beforeDraw(chart, easing) {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      const top = chartArea.top; // Use a value of 0 here to include the legend

      ctx.save();
      ctx.fillStyle = '#000';

      ctx.fillRect(0, top, chartArea.right - chartArea.left, chartArea.bottom - top);
      ctx.restore();
    }
  }*/
];

  public colors: Color[] = [
    { backgroundColor: [ '#009245','#dc3545','#B1AFAF' ] }
  ];

}
