import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../servicios/usuario.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WebsocketsService } from '../../servicios/websockets.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  public errorEncuesta = false;
  public formSubmitted = false;
  public usuarioForm = this.fb.group({
    nombre:    ['' , [ Validators.required] ],
    edad:    ['' , [ Validators.required] ],
    genero: ['', Validators.required ],
    escolaridad: ['', Validators.required ],
    estado: ['', Validators.required ],
    carrera: ['', Validators.required ], 
  });


  constructor(
    private usuario: UsuarioService,
    private router: Router,
    private usuarioService: UsuarioService,
    private fb: FormBuilder,
    public wsService: WebsocketsService,
  ) { 

  }

  ngOnInit() {
  }

  registrar(){ 
    this.formSubmitted = true;
    
    if ( this.usuarioForm.invalid ) {
      console.log("Form invalid");
      return;
    }

    this.usuarioService.registro( this.usuarioForm.value )
      .subscribe( resp => {
        console.log(resp); 
        if( resp.ok ){
          this.usuario.guardarToken( resp.token ); 
          this.wsService.idEncuesta = resp.idEncuesta;
          
          this.errorEncuesta = false;
          this.router.navigate(['/espera']); 

          console.log("Solicitando acceso");
          this.wsService.emit('acceso',{nombre: this.usuarioForm.value.nombre , idEncuesta: resp.idEncuesta});
          this.wsService.iniciarPreguntas();

        }else{
          this.errorEncuesta = true;
          this.wsService.idEncuesta = '';
          console.log(resp.msg, 'Error' );
        }  
      }, err => {
        this.errorEncuesta = true;
        this.wsService.idEncuesta = '';
        console.log("Error", err.error); 
        console.log(err.error.msg, 'Error');
      });

  } 
  campoNoValido( campo: string ): boolean {
    
    if ( this.usuarioForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }
}
