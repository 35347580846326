import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketsService } from '../../servicios/websockets.service';

@Component({
  selector: 'app-agradecimiento',
  templateUrl: './agradecimiento.component.html',
  styleUrls: ['./agradecimiento.component.scss']
})
export class AgradecimientoComponent implements OnInit {

  public labels1: string[] = ['Correctas', 'Incorrectas', 'Sin Contestar'];
  public data1 =  [0, 0, 0] ;

  constructor(
    private router: Router,
    public wsService: WebsocketsService
  ) { }

  ngOnInit() {
    this.data1[0] = this.wsService.correctas;
    this.data1[1] = this.wsService.incorrectas;
    this.data1[2] = this.wsService.sinContestar;
  }

  irInicio(){
    this.router.navigate(['/inicio']); 
  }
}
