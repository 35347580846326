import { Component } from '@angular/core';
import { WebsocketsService } from 'src/app/servicios/websockets.service';

@Component({
  selector: 'app-estatus',
  templateUrl: './estatus.component.html',
  styleUrls: ['./estatus.component.scss']
})
export class EstatusComponent {

  constructor( 
    public wsService: WebsocketsService
  ) {
     
   }


}
