import { Injectable } from '@angular/core';
import { WebsocketsService } from './websockets.service';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    public usuario: UsuarioService,
    public wsService: WebsocketsService
  ) { }

    sendMessage( mensaje: string ) {

      const payload = {
        token       : this.usuario.token,
        idEncuesta  : this.wsService.idEncuesta ,
        idPregunta  : this.wsService.idPregunta ,
        respuesta   : mensaje
      };
      this.wsService.emit('mensaje', payload );
      this.wsService.idPregunta = '';

    }

    getMessages() {
      return this.wsService.listen('mensaje-nuevo');
    }

}
