import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';   
import { ReactiveFormsModule } from '@angular/forms'; 

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { configuracion } from '../config'; 
const config: SocketIoConfig = { 
  url: configuracion.base_url , 
  options: {} 
};

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { RegistroComponent } from './paginas/registro/registro.component';
import { EsperaComponent } from './paginas/espera/espera.component';
import { PreguntaComponent } from './paginas/pregunta/pregunta.component';
import { AgradecimientoComponent } from './paginas/agradecimiento/agradecimiento.component';
import { EstatusComponent } from './paginas/estatus/estatus.component';
import { HttpClientModule } from '@angular/common/http';
//Graficas  
import { ChartsModule } from 'ng2-charts';
import { ComponentsModule } from './components/components.module';
 
@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    RegistroComponent,
    EsperaComponent,
    PreguntaComponent,
    AgradecimientoComponent,
    EstatusComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,     
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule.forRoot(config), 
    ChartsModule,
    ComponentsModule
  ],
  exports: [  
    ComponentsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
