import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/servicios/chat.service';
import { WebsocketsService } from '../../servicios/websockets.service';

@Component({
  selector: 'app-espera',
  templateUrl: './espera.component.html',
  styleUrls: ['./espera.component.scss']
})
export class EsperaComponent implements OnInit {

  constructor(
    private router: Router,
    public chatService: ChatService, 
    public wsService: WebsocketsService
    ) {  
      if( this.wsService.idEncuesta == ""){
        this.router.navigate(['/registro']);  
      }
    }

  ngOnInit() {
  }

}
